<template>
    <div class="col-md-2 text-center pointer">
        <div style="font-size: .75rem">{{ title }}</div>
        <div
            class="border border-dark d-flex justify-content-center align-items-center"
            :class="[count > 0 ? alert_color : 'no-alert']"
        >
            <div class="spinner-grow text-secondary spinner-grow-sm my-3" role="status" v-if="loading">
                <span class="visually-hidden">Loading...</span>
            </div>
            <span class="fs-2" v-else>{{ count > 0 ? count : ' - ' }}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AlertBox',
    components: {
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        count: {
            type: Number,
            default: 0
        },
        loading: {
            type: Number,
            default: 0,
        },
        alert_color: {
            type: String,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
