<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex align-items-center">
                                <div  class="text-center alert-equipments" style="width:50px; height:50px;">
                                    <h1><i class="bi bi-exclamation-lg"></i></h1>
                                </div>
                                <h4 class="modal-title ms-3">
                                    備品不足一覧
                                </h4>
                            </div>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <template v-if="equipment_alerts.length">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-md-3 text-center">日付</th>
                                            <th class="col-md-5 text-center">備品名</th>
                                            <th class="col-md-2 text-center">所持数</th>
                                            <th class="col-md-14"><span class="ps-3">使用詳細</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="alert in equipment_alerts" :key="alert">
                                            <tr>
                                                <td class="text-center">{{alert.alert_date_display}}</td>
                                                <td class="text-center">{{ alert.equipment.equipment_name }}</td>
                                                <td class="text-center">{{ alert.equipment.quantity }} {{ alert.equipment.unit }}</td>
                                                <td>
                                                    <template v-for="alert_schedule in alert.schedules" :key="alert_schedule">
                                                        <div class="row align-items-center my-2">
                                                            <div class="col-md-16">
                                                                <span style="font-size:.9rem;"><i class="bi me-1" :class="alert_schedule.schedule.schedule_icon"></i></span>
                                                                {{ alert_schedule.schedule.schedule_name_display }}
                                                            </div>
                                                            <div class="col-md-3 text-end">
                                                                {{ displayEquipmentUsageCount(alert.equipment, alert_schedule.schedule.equipments) }}
                                                            </div>
                                                            <div class="col-md-5 text-end">
                                                                <button
                                                                    class="btn btn-outline-primary btn-sm"
                                                                    @click="openScheduleModal(alert_schedule.schedule)"
                                                                >
                                                                    表示
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </template>
                            <template v-else>
                                <alert-no-record
                                    text="現在アラートはありません"
                                ></alert-no-record>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import Schedule from '@/models/entities/schedule';

export default {
    name: 'AlertEquipmentModal',
    components: {
        AlertNoRecord,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        equipment_alerts: {
            type: Array,
            default: () => ([])
        },
    },
    emits: [
        'close',
        'setSchedule'
    ],
    data() {
        return {
            target_schedule: new Schedule(),
        }
    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        close() {
            this.$emit('close');
        },
        openScheduleModal(schedule) {
            this.target_schedule = schedule;
            this.$emit('setSchedule', this.target_schedule);
        },
        displayEquipmentUsageCount(equipment, schedule_equipments) {
            let schedule_equipment = schedule_equipments.find(schedule_equipment => schedule_equipment.equipment.equipment_id === equipment.equipment_id);
            return schedule_equipment.quantity + ' ' + equipment.unit;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
