<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex align-items-center">
                                <div class="text-center" :class="alert_color" style="width:50px; height:50px;">
                                    <h1><i class="bi bi-exclamation-lg"></i></h1>
                                </div>
                                <h4 class="modal-title ms-3">
                                    {{ modal_title }}
                                </h4>
                            </div>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <template v-if="alert_schedules.length">
                                <div class="alert alert-danger mb-3" role="alert">
                                    <i class="bi bi-exclamation-triangle"></i>{{ alert_message }}
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-3">日付</th>
                                            <th class="col-13">スケジュール名</th>
                                            <th class="col-6">学校担当者名</th>
                                            <th class="col-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="alert_schedule in alert_schedules" :key="alert_schedule">
                                            <tr>
                                                <td>{{ alert_schedule.schedule_date_display }}</td>
                                                <td>
                                                    <span style="font-size:.9rem;"><i class="bi me-1" :class="alert_schedule.schedule_icon"></i></span>
                                                    {{ alert_schedule.schedule_name_display }}
                                                </td>
                                                <td>{{ alert_schedule.school.employee.photographer_name }}</td>
                                                <td>
                                                    <button
                                                        class="btn btn-outline-primary btn-sm"
                                                        @click="openScheduleModal(alert_schedule)"
                                                    >
                                                        表示
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </template>
                            <template v-else>
                                <alert-no-record
                                    text="現在アラートはありません"
                                ></alert-no-record>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import Schedule from '@/models/entities/schedule';

export default {
    name: 'ScheduleAlertModal',
    components: {
        AlertNoRecord,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        alert_schedules: {
            type: Array,
            default: () => ([])
        },
        modal_title: {
            type: String,
            default: ''
        },
        alert_message: {
            type: String,
            default: ''
        },
        alert_color: {
            type: String,
        }
    },
    emits: [
        'close',
        'setSchedule'
    ],
    data() {
        return {
            target_schedule: new Schedule(),
        }
    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        close() {
            this.$emit('close');
        },
        openScheduleModal(schedule) {
            this.target_schedule = schedule;
            this.$emit('setSchedule', this.target_schedule);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
