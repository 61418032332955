<template>
    <transition name="fade">
        <div>
            <div class="offcanvas-backdrop fade show"></div>
            <div class="offcanvas show" :class="placement" tabindex="-1" style="visibility: visible;">
                <div class="offcanvas-header">
                    <h5>{{ title }}</h5>
                    <button type="button" class="btn-close text-reset" @click="close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <button-exec
                                text="追加"
                                icon="bi-calendar2-plus"
                                color="btn-link"
                                size="btn-sm"
                                style="font-size:.8rem;"
                                @click="openCreateScheduleModal"
                            ></button-exec>
                        </div>
                        <div>{{ date_for_input }}</div>
                    </div>
                    <slot/>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import { dateYnjQ } from '@/utilities/date-format';
import ButtonExec from '@/components/buttons/ButtonExec';

export default {
    name: 'ScheduleOffcanvas',
    components: {
        ButtonExec,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    props: {
        modelValue: {
            type: Object
        },
        placement: {
            /**
             * 左部に配置 offcanvas-start
             * 右部に配置 offcanvas-end
             * 上部に配置 offcanvas-top
             * 下部に配置 offcanvas-bottom
             */
            type: String,
            default: 'offcanvas-top'
        },
        title: {
            type: String,
            default: 'title'
        },
        date: {
            type: String,
            default: '',
        }
    },
    emits: [
        'update:modelValue',
        'close',
        'remove',
        'create',
        'update',
        'createNewSchedule'
    ],
    data() {
        return {
        }
    },
    computed: {
        date_for_input() {
            return dateYnjQ(this.date);
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        openCreateScheduleModal() {
            this.$emit('createNewSchedule');
        },
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
