<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex align-items-center">
                                <div  class="text-center alert-unassigned" style="width:50px; height:50px;">
                                    <h1><i class="bi bi-exclamation-lg"></i></h1>
                                </div>
                                <h4 class="modal-title ms-3">
                                    未配置一覧
                                </h4>
                            </div>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <template v-if="unassigned_invitations.length">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-md-3 text-center">日付</th>
                                            <th class="col-md-5 text-center">応援カメラマン</th>
                                            <th class="col-md-16 text-center">備考</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="invitation in unassigned_invitations" :key="invitation.invitation_id">
                                            <tr>
                                                <td class="text-center">{{ invitation.invitation_date_display }}</td>
                                                <td class="text-center">{{ invitation.partner?.photographer_name }}</td>
                                                <td class="text-center">{{ invitation.memo }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </template>
                            <template v-else>
                                <alert-no-record
                                    text="現在アラートはありません"
                                ></alert-no-record>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AlertNoRecord from '@/components/tools/AlertNoRecord';

export default {
    name: 'AlertUnassignedModal',
    components: {
        AlertNoRecord,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        unassigned_invitations: {
            type: Array,
            default: () => ([])
        },
    },
    emits: [
        'close',
        'setSchedule'
    ],
    data() {
        return {

        }
    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        close() {
            this.$emit('close');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
