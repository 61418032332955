import Enum from '../enum'

/**
 * スケジュール カメラマン検索条件
 *
 * [1:学校担当 2:カメラマン]
 */
class SearchType extends Enum {
    static EMPLOYEE = 1; // 学校担当
    static PHOTOGRAPHER = 2; // カメラマン

    static values() {
        return {
            [this.EMPLOYEE]: '学校担当',
            [this.PHOTOGRAPHER]: 'カメラマン',
        }
    }
}

export default SearchType;