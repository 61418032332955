import Enum from '../enum'

/**
 * カレンダー カメラマン不足ステータス
 */
class ShortageStatus extends Enum {
    static NORMAL = 0; // 正常
    static DANGER = 1; // 不足
    static WARNING = 2; // 不足気味

    static values() {
        return {
            [this.NORMAL]: '正常',
            [this.DANGER]: '不足',
            [this.WARNING]: '不足気味',
        }
    }
}

export default ShortageStatus;