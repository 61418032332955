<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex align-items-center">
                                <div  class="text-center" :class="alert_color" style="width:50px; height:50px;">
                                    <h1><i class="bi bi-exclamation-lg"></i></h1>
                                </div>
                                <h4 class="modal-title ms-3">
                                    {{ modal_title }}
                                </h4>
                            </div>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <template v-if="shortage_calendars.length">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">日付</th>
                                            <th class="text-center">必要カメラマン数</th>
                                            <th class="text-center">確保済カメラマン数</th>
                                            <th class="text-center">過不足</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="calendar in shortage_calendars" :key="calendar">
                                            <tr>
                                                <td class="text-center">
                                                    <button
                                                        type="button"
                                                        class="btn btn-link"
                                                        @click="setAlertDate(calendar)"
                                                    >
                                                        {{ calendar.date_display }}
                                                    </button>
                                                </td>
                                                <td class="text-center">{{ calendar.required_photographer_number }}</td>
                                                <td class="text-center">{{ calendar.holding_number }}</td>
                                                <td class="text-center">{{ calendar.shortage_number }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </template>
                            <template v-else>
                                <alert-no-record
                                    text="現在アラートはありません"
                                ></alert-no-record>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import ShortageStatus from '@/models/enums/schedule/shortage-status';

export default {
    name: 'AlertShortageModal',
    components: {
        AlertNoRecord,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        shortage_calendars: {
            type: Array,
            default: () => ([])
        },
        shortage_status: {
            type: Number,
            default: 0
        },
    },
    emits: [
        'close',
        'setAlertDate'
    ],
    data() {
        return {
            ShortageStatus: ShortageStatus,
        }
    },
    mounted() {

    },
    watch: {

    },
    computed: {
        modal_title() {
            let calendar_count = this.shortage_calendars.length;
            let title;
            if (this.shortage_status === ShortageStatus.DANGER) {
                title = '確保済カメラマン不足';
            } else if (this.shortage_status === ShortageStatus.WARNING) {
                title = '確保済みカメラマン不足気味';
            }
            return `${title}（${calendar_count}日）`;
        },
        alert_color() {
            let css;
            if (this.shortage_status === ShortageStatus.DANGER) {
                css = 'alert-shortage-danger';
            } else if (this.shortage_status === ShortageStatus.WARNING) {
                css = 'alert-shortage-warning';
            }

            return css;
        }

    },
    methods: {
        close() {
            this.$emit('close');
        },
        setAlertDate(calendar) {
            this.close();
            this.$emit('setAlertDate', calendar.date_for_input);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
