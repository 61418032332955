import Enum from '../enum'

/**
 * 休暇トラン 休暇タイプ
 *
 * [1:公休 2:有給]
 */
class AbsenceType extends Enum {
    static DAY_OFF = 1; // 公休
    static PAID = 2; // 有給

    static values() {
        return {
            [this.DAY_OFF]: '公休',
            [this.PAID]: '有給',
        }
    }
}

export default AbsenceType;