import Enum from '../enum'

/**
 * カレンダーマスタ 祝日フラグ
 */
class IsHoliday extends Enum {
    static YES = 1; // 祝日
    static NO = 0; //祝日でない

    static values() {
        return {
            [this.YES]: '祝日',
            [this.NO]: '祝日でない',
        }
    }
}

export default IsHoliday;