import Enum from '../enum'

/**
 * スケジュール アラートか否か
 */
class IsAlert extends Enum {
    static NO = 0; // 正常
    static YES = 1; // アラート

    static values() {
        return {
            [this.NO]: '正常',
            [this.YES]: 'アラート',
        }
    }
}

export default IsAlert;