import { isNud } from '@/utilities/typing';
import { dateYmd } from '@/utilities/date-format';
import Equipment from '@/models/entities/equipment';
import AlertEquipmentSchedule from '@/models/entities/alert-equipment-schedule';

/**
 * 備品不足トラン エンティティ
 */
class AlertEquipment {
    alert_equipment_id;
    alert_date;
    equipment;
    schedules;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.alert_equipment_id = parseInt(properties.alert_equipment_id, 10);
        this.equipment = new Equipment(properties.equipment);
        this.alert_date = properties.alert_date;
        this.schedules = [];
        if ('schedules' in properties) {
            for (let schedule of properties.schedules) {
                this.schedules.push(new AlertEquipmentSchedule(schedule));
            }
        }
    }

    get alert_date_display() {
        return dateYmd(this.alert_date);
    }
}

export default AlertEquipment;
