<template v-if="schedule_photographers_shortened_name.length">
    <template v-for="schedule_photographer in schedule_photographers_shortened_name" :key="schedule_photographer">
        <template v-if="!$helper.isNud(schedule_photographer.photographer)">
            <template v-if="schedule_photographer.ignore_alert === IgnoreAlert.IGNORE">
                (<i class="bi bi-bell-slash"></i>{{ schedule_photographer.photographer.shortened_name }})
            </template>
            <template v-else>
                ({{ schedule_photographer.photographer.shortened_name }})
            </template>
        </template>
        <template v-else>
            {{ schedule_photographer }}
        </template>
    </template>
    <template v-if="shortened_number > 0">
        {{ ` 他${shortened_number}` }}
    </template>
</template>

<script>
import IgnoreAlert from '@/models/enums/schedule/ignore-alert';
import Schedule from '@/models/entities/schedule';

export default {
    name: 'SchedulePhotographerShortenedName',
    components: {
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        schedule: {
            type: Object,
            default: new Schedule(),
        }
    },
    data() {
        return {
            // Enums
            IgnoreAlert: IgnoreAlert,

            schedule_photographers_shortened_name: [],
            shortened_number: null,
        }
    },
    mounted() {
        this.setPhotographersShortenedName();
    },
    watch: {
    },
    computed: {
    },
    methods: {
        setPhotographersShortenedName() {
            let shortened_names = [];
            this.schedule.photographers.forEach((schedule_photographer) => {
                shortened_names.push(schedule_photographer);
            });

            // もし必要カメラマン数 > 登録カメラマン数だったら、不足分を()で埋める
            if (this.schedule.photographer_number > this.schedule.photographers.length) {
                let diff = this.schedule.photographer_number - this.schedule.photographers.length;
                for (let i = 1; i <= diff; i++) {
                    shortened_names.push('( )');
                }
            }

            this.schedule_photographers_shortened_name = shortened_names.splice(0, 3);
            this.shortened_number = shortened_names.length;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
