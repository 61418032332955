import { isNud } from '@/utilities/typing'
import Schedule from '@/models/entities/schedule';

/**
 * アラート備品不足スケジュール エンティティ
 */
class AlertEquipmentSchedule {
    alert_equipment_schedule_id;
    schedule;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.alert_equipment_schedule_id = properties.alert_equipment_schedule_id;
        this.schedule = new Schedule(properties.schedule);
    }
}

export default AlertEquipmentSchedule;
