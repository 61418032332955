import { isNud } from '@/utilities/typing'
import Schedule from '@/models/entities/schedule';

/**
 * アラートダブルブッキングスケジュール エンティティ
 */
class AlertDoubleBookingSchedule {
    alert_double_booking_schedule_id;
    schedule;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.schedule = new Schedule(properties.schedule);
    }
}

export default AlertDoubleBookingSchedule;
