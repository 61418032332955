import { isNud } from '@/utilities/typing';
import { dateYmd, dateYmdForInputDate, dateYnjQ, dateQ } from '@/utilities/date-format';
import Schedule from '@/models/entities/schedule';
import Invitation from '@/models/entities/invitation';
import Absence from '@/models/entities/absence';
import AlertEquipment from '@/models/entities/schedule-equipment';

/**
 * カレンダーマスタ エンティティ
 */
class Calendar {
    calendar_id;
    date;
    required_number;
    holding_number;
    spare_number;
    shortage_status;
    is_holiday;

    invitations;
    absences;
    alert_equipments;
    schedules;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.calendar_id = properties.calendar_id;
        this.date = properties.date;
        this.schedule_date = properties.schedule_date;
        this.required_number = properties.required_number;
        this.holding_number = properties.holding_number;
        this.spare_number = properties.spare_number;
        this.shortage_status = properties.shortage_status;
        this.is_holiday = properties.is_holiday;

        this.invitations = [];
        if ('invitations' in properties) {
            for (let invitation of properties.invitations) {
            this.invitations.push(new Invitation(invitation));
            }
        }

        this.absences = [];
        if ('absences' in properties) {
            for (let absence of properties.absences) {
            this.absences.push(new Absence(absence));
            }
        }
        this.alert_equipments = [];
        if ('alert_equipments' in properties) {
            for (let alert_equipment of properties.alert_equipments) {
            this.alert_equipments.push(new AlertEquipment(alert_equipment));
            }
        }
        this.schedules = [];
        if ('schedules' in properties) {
            for (let schedule of properties.schedules) {
                this.schedules.push(new  Schedule(schedule));
            }
        }
    }

    /**
     * getter
     */
    get header_day() {
        let dt = new Date(this.date);

        return dt.getDate();
    }

    get photographer_count() {
        let spare = this.spare_number > 0 ? `(${this.spare_number})` : '';

        return `${this.required_number}${spare}/${this.holding_number}`;
    }

    get required_photographer_number() {
        let spare = this.spare_number > 0 ? `(${this.spare_number})` : '';

        return `${this.required_number}${spare}`;
    }

    get date_display() {
        return dateYmd(this.date);
    }

    get date_for_input() {
        return dateYmdForInputDate(this.date);
    }

    get shortage_number () {
        return (this.holding_number - this.required_number);
    }

    get full_date_display() {
        return dateYnjQ(this.date);
    }

    get day_display() {
        return dateQ(this.date);
    }
}

export default Calendar;
