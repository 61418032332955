<template>
    <button :type="type" class="btn btn-outline-primary" :class="[size, {'disabled': disabled}]">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'ButtonSearch',
    props: {
        text: {
            type: String,
            default: '検索',
        },
        icon: {
            type: String,
            default: 'bi-search',
        },
        type: {
            type: String,
            default: 'button',
        },
        size: {
            type: String,
            default: '',
        },
        disabled: {
            type: Number,
            default: 0
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
