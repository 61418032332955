import Enum from '../enum'

/**
 * 休暇モーダルチェックボックス 休みかどうか
 */
class IsAbsent extends Enum {
    static NO = 0; // YES
    static YES = 1; // NO

    static values() {
        return {
            [this.NO]: 'YES',
            [this.YES]: 'NO',
        }
    }
}

export default IsAbsent;